<template>
  <div class="highlight-text">
    <span>{{ text }}</span>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    highlightText: {
      required: false,
      type: String,
      default: "recommended"
    }
  },
  data() {
    return {
      $text: ""
    };
  },
  computed: {
    text() {
      return this.$data.$text;
    }
  },
  watch: {
    highlightText: {
      immediate: true,
      handler(val) {
        this.$data.$text = _.upperCase(val);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.highlight-text {
  color: #f5f5f5;
  margin-left: 20px;
  letter-spacing: 0.05rem;
  background: #4db3f6;
  padding: 7px 14px;
  height: 14px;
  font-size: 0.78125rem;
  font-weight: 900;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
