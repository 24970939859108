<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      class="node-details-form"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name" />
      </el-form-item>

      <el-form-item
        prop="trigger_campaign_node.data.campaign_id"
        :label="__('On-Demand Campaign')"
      >
        <el-select
          v-model="nodeToBind.trigger_campaign_node.data.campaign_id"
          default-first-option
          :placeholder="__('Select an on-demand campaign')"
          clearable
        >
          <el-option
            v-for="item in onDemandCampaigns"
            :key="item.campaign_name"
            :label="item.campaign_name"
            :value="item.campaign_id"
          />
        </el-select>
      </el-form-item>

      <div v-if="this.selectedCampaignId">
        <el-form-item :label="__('Task name')">
          <div class="form-item-group">
            <div class="read-only">{{ taskName }}</div>
            <div v-if="isUnMappedTask && !isChatBot" class="info">
              <div class="warning-exclamation" />
              <div
                style="margin-left: 5px; display: flex; flex-direction: column"
              >
                <div>
                  <!-- eslint-disable-next-line -->
                  {{ __("Campaign will not run as expected because the task associated with the selected campaign does not have an assigned number.") }}
                </div>
                <div>
                  <!-- eslint-disable-next-line -->
                  {{ __("You can fix this by assigning a phone number to the above task.") }}
                  <!--                  <span-->
                  <!--                    style="color:#4DB3F6; font-size: 0.8125rem"-->
                  <!--                    class="cell-item-pointer"-->
                  <!--                    >Assign number</span>-->
                </div>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="Campaign CLI" v-if="isChatBot">
          <div class="read-only">{{ campaignCLI }}</div>
        </el-form-item>

        <div v-if="!isUnMappedTask">
          <el-form-item
            prop="trigger_campaign_node.data.property_rules.data.phone_number"
            :label="__('Phone Number')"
          >
            <expression-input
              v-model="phoneNumber"
              :complex-variables="complexVariables"
              :placeholder="
                __('Enter phone number using the expression builder')
              "
              @edit-input="displayExpressionBuilderModal = true"
            ></expression-input>
          </el-form-item>
        </div>
        <div v-if="!isUnMappedTask || isChatBot"></div>
        <el-form-item
          v-if="!isEmptyVariableRules"
          prop="trigger_campaign_node.data.variable_rules.data"
          :label="__('Parameters')"
        >
          <template #label>
            <div style="display: flex; align-items: center">
              <span>{{ __("Parameters") }}</span>
              <div v-if="!isChatBot">
                <highlight-text highlight-text="recommended" />
              </div>
            </div>
          </template>
          <extract-words
            v-model="variableRules"
            :variables="singleValuedVariables"
            :rule_title="__('Value')"
            :add-empty-row="false"
            :use-immutable-variables="true"
            :use-secure-variables="false"
          />
        </el-form-item>
      </div>
    </el-form>
    <expression-builder-dialog
      v-if="displayExpressionBuilderModal"
      v-model="phoneNumber"
      :show-expression-builder="displayExpressionBuilderModal"
      @input="handleCancel"
      @cancel="handleCancel"
      @close="handleCancel"
    />
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import _ from "lodash";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import { NODE_TYPES, TASK_TYPES } from "@/constants/nodes";
import ExtractWords from "@/views/build/callflow/components/node-type-forms/components/ExtractWords";
import HighlightText from "@/components/HighlightText";

const initTriggerCampaignNode = {
  node_type: NODE_TYPES.TRIGGER_CAMPAIGN.NODE_TYPE,
  trigger_campaign_node: {
    data: {
      campaign_id: null,
      property_rules: {
        data: {
          phone_number: ""
        }
      },
      variable_rules: {
        data: []
      }
    }
  }
};
export default {
  name: "TriggerCampaignNode",
  components: {
    HighlightText,
    ExtractWords,
    ExpressionBuilderDialog,
    ExpressionInput
  },
  mixins: [BaseNode],
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (!value) {
        callback(__("Phone number is required"));
      } else if (_.isEmpty(JSON.parse(value))) {
        callback(__("Phone number is required"));
      } else {
        callback();
      }
    };

    // const validateTask = (rule, value, callback) => {
    //   if (this.isUnMappedTask) {
    //     callback("Select campaign with an executable task");
    //   } else {
    //     callback();
    //   }
    // };
    return {
      rules: {
        trigger_campaign_node: {
          data: {
            campaign_id: [
              {
                required: true,
                message: __("Campaign is required"),
                trigger: "blur"
              }
              // for now, we will let the users create a trigger campaign node
              // with a campaign mapped to non executable task (because of no service numbers are mapped to that task)

              // { validator: validateTask, trigger: "blur" }
            ],
            property_rules: {
              data: {
                phone_number: [
                  {
                    required: true,
                    validator: validatePhoneNumber,
                    trigger: "blur"
                  }
                ]
              }
            }
          }
        }
      },
      displayExpressionBuilderModal: false,
      initialTriggerNodeData: {}
    };
  },
  computed: {
    selectedCampaignId: {
      get() {
        return _.get(
          this.nodeToBind,
          "trigger_campaign_node.data.campaign_id",
          ""
        );
      },
      set(val) {
        _.set(this.nodeToBind, "trigger_campaign_node.data.campaign_id", val);
      }
    },
    isEmptyVariableRules() {
      return _.isEmpty(this.variableRules);
    },
    phoneNumber: {
      get() {
        return (
          this.nodeToBind.trigger_campaign_node.data.property_rules.data
            .phone_number || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.trigger_campaign_node.data.property_rules.data.phone_number = expression;
      }
    },
    selectedCampaign() {
      return _.find(this.onDemandCampaigns, {
        campaign_id: this.selectedCampaignId
      });
    },
    campaignCLI() {
      return !this.selectedCampaign
        ? ""
        : this.selectedCampaign.campaign_caller_id;
    },
    taskType() {
      return _.isEmpty(this.selectedCampaign)
        ? ""
        : this.selectedCampaign.campaign_channel;
    },
    isChatBot() {
      return this.taskType === TASK_TYPES.CHATBOT;
    },
    taskName() {
      return !this.selectedCampaign ? "" : this.selectedCampaign.task_name;
    },
    isUnMappedTask() {
      if (!this.selectedCampaign) {
        return false;
      }
      let phoneNumberMap = _.find(this.phoneNumberToTaskMap, {
        task_id: this.selectedCampaign.task_id
      });
      return !phoneNumberMap;
    },
    passedParameterIds() {
      if (!this.selectedCampaign) {
        return [];
      }

      return this.selectedCampaign.campaign_passed_params.data;
    },
    passedParameters() {
      if (!this.selectedCampaign) {
        return [];
      }
      return _(this.singleValuedVariables)
        .filter(variable =>
          this.passedParameterIds.includes(variable.variable_id)
        )
        .map(variable => ({
          variable_id: variable.variable_id,
          variable_name: variable.variable_name
        }))
        .values()
        .value();
    },
    triggerCampaignNodeVariableRules() {
      return this.nodeToBind.trigger_campaign_node.data.variable_rules.data;
    },
    variableRules: {
      get() {
        const { data: variable_rules } = _.cloneDeep(
          this.nodeToBind.trigger_campaign_node.data.variable_rules
        );
        return _.isEmpty(variable_rules)
          ? []
          : this.filterOutUnwantedParams(variable_rules);
      },
      set(val) {
        if (
          // update only if there is a change in word rules
          !_.isEqual(this.triggerCampaignNodeVariableRules, val) ||
          _.isEmpty(this.triggerCampaignNodeVariableRules)
        ) {
          this.$set(
            this.nodeToBind.trigger_campaign_node.data.variable_rules,
            "data",
            val
          );
        }
      }
    }
  },
  methods: {
    initializeTriggerCampaignNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initTriggerCampaignNode)
      );
    },
    initializePassedParameterValues() {
      return _.map(this.passedParameters, parameter =>
        Object.assign({}, parameter, { rule_value: "" })
      );
    },
    handleCancel() {
      this.displayExpressionBuilderModal = false;
    },
    // remove the parameters present in variable rules table, but not part of the latest
    // passed parameter list
    filterOutUnwantedParams(rules) {
      return _.filter(rules, rule =>
        this.passedParameterIds.includes(rule.variable_id)
      );
    },
    cleanUpNodeToPrepareForSubmit() {
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      this.createOrEditNode();
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.trigger_campaign_node)
    ) {
      this.initializeTriggerCampaignNodeData();
    } else {
      // if the selected campaign id is null, init the data with empty trigger campaign node details
      // as we had done for creating a new trigger campaign node
      if (!this.selectedCampaignId) {
        this.initializeTriggerCampaignNodeData();
        // set the node id on trigger node data details
        _.set(
          this.nodeToBind,
          "trigger_campaign_node.data.node_id",
          this.nodeToBind.node_id
        );
      }

      // if there are any new variables added to passed parameters, get them into the list
      this.variableRules = _.merge(
        this.initializePassedParameterValues(),
        _.cloneDeep(this.variableRules)
      );
    }
    this.initialTriggerNodeData = _.cloneDeep(
      this.nodeToBind.trigger_campaign_node
    );
  },
  watch: {
    selectedCampaignId: {
      handler(val) {
        if (val === this.initialTriggerNodeData.data.campaign_id) {
          this.variableRules = this.initializePassedParameterValues();

          // get back the initial trigger_campaign_node data except the the changed phone number
          this.nodeToBind = _.merge(
            this.nodeToBind,
            _.cloneDeep({
              trigger_campaign_node: _.omit(this.initialTriggerNodeData, [
                "data.property_rules.data.phone_number"
              ])
            })
          );
        } else if (val) {
          this.variableRules = this.initializePassedParameterValues();
        } else {
          this.variableRules = [];
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.node-details-form {
  .form-item-group {
    display: flex;
    flex-direction: column;

    .info {
      color: #696969;
      display: flex;
      line-height: 1.5;
      margin-top: 5px;
      font-size: 0.8125rem;
      letter-spacing: 0.005rem;
    }
  }
}
</style>
